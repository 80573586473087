/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { StylesProvider } from '@material-ui/core/styles';
import { theme } from './src/theme/theme-vars';

export const wrapRootElement = ({ element }) => (
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </StylesProvider>
);
