const theme = {
  colours: {
    red: '#ED2924',
    nimbleRed: '#DB1A1A',
    black: '#1E1E1E',
    white: '#FFFFFF',
    orange: '#ED2924',
    pink: '#FBD4CF',
    lightPink: '#FDE9E9',
    green: '#007A68',
    grey: '#E6E6E6',
    darkGrey: '#353535',
    greytext: '#bdbdbd',
    yellow: '#F7ED00',
    textColor: '#1E1E1E',
    menuBackground: '#1E1E1E',
    default: { light: '#595959', dark: '#1E1E1E', hover: '#D8D8D8' }, // text color
    primary: { light: '#EA2C2E', dark: '#e6e6e6', shallow: '#F8A99F' }, // red
    border: '#CECECE',
    dimGray: '#DDDDDD',
    slate: '#212121',
    slateLight: '#A8A8A8',
    neutralText: '#4F4F4F',
    cream: '#FFFCF5',
    darkGreen: '#017A68',
    lightGreen: '#D9EBE8',
    greyBack: '#F2F2F2'
  },

  font: {
    nimbleFont: 'SharpGroteskBook, sans-serif',
    nimbleHeadingFont: 'SharpGroteskBold, sans-serif',
    nimbleBoldFont: 'SharpGroteskSmBold, sans-serif'
  },
  sectionPadding: {
    sectionPaddingTbDesktop: '70px',
    sectionPaddingTbMobile: '50px',
    desktopPaddingLr: '40px',
    mobilePaddingLr: '15px'
  },
  sectionMargin: {
    headerMarginXPhone: '15px',
    headerMarginXDesktop: '30px'
  },
  screens: {
    screenLarge: '960px',
    screenMedium: '767px'
  },
  components: {
    componentStandardWidthMax: '1440px',
    phoneWidthMax: '800px',
    desktopWidthMin: '801px',
    dotSize: '9px',
    maxWidth: '1440px'
  },
  mediaBreakpoints: {
    desktop: '(min-width: 1440px)',
    tablet: '(min-width: 480px) and (max-width: 1439px)',
    mobile: '(max-width: 1239px)',
    smallScreen: '(max-width: 479px)'
  },
  breakpoints: { // new
    max: {
      xs: '400px',
      sm: '550px',
      md: '767px',
      lg: '991px',
      xl: '1199px'
    },
    min: {
      xs: '401px',
      sm: '551px',
      md: '768px',
      lg: '992px',
      xl: '1200px'
    }
  },
  misc: {
    mobileMenuTrigger: '990px',
    textShadow: '0 0 3px #E3367F',
    cardRadius: '0px',
    buttonRadius: '4px',
    sideMenuWidth: '270px',
    panelRadius: '20px',
    starEmptyColor: '#F8A99F',
    starFillColor: '#ED2924'
  },
  size: {
    small: '12px',
    medium: '14px',
    large: '18px',
    xlarge: '21px',
    xxlarge: '24px',
    giant: '32px',
    xgiant: '36px',
    xxgiant: '56px'
  },
  transition: {
    transition300ms: {
      transition: 'all .3s ease-in-out',
      '-webkit-transition': 'all .3s ease-in-out',
      '-o-transition': 'all .3s ease-in-out'
    }
  }
};

export { theme };
